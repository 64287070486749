interface InitializersState {
    finished: number;
    total: number;
}

const DEFAULT_STATE: InitializersState = {
    finished: 0,
    total: 0,
};

const getObservableStateProxy = (stateObj: InitializersState, onFinishedCallback: () => void): InitializersState => {
    const changeHandler: ProxyHandler<InitializersState> = {
        set(target, prop, value) {
            target[prop as keyof InitializersState] = value;
            if (prop === 'finished' && target.finished === target.total && target.total > 0) {
                onFinishedCallback();
            }
            return true;
        },
    };
    return new Proxy(stateObj, changeHandler);
};

class InitializerService {
    private state: InitializersState;

    checkInitializers(onFinishedCallback: () => void) {
        this.state = getObservableStateProxy(DEFAULT_STATE, onFinishedCallback);
        const allInitializers: NodeListOf<HTMLElement> = document.querySelectorAll('[data-js-init]');

        if (allInitializers.length > 0) {
            this.state.total = allInitializers.length;
            allInitializers.forEach((initializer) => {
                this.runInitializer(initializer);
            });
        } else {
            onFinishedCallback();
        }
    }

    runInitializer(initializer: HTMLElement) {
        const initializerName = initializer.dataset.jsInit;
        switch (initializerName) {
            case 'header-mobile':
                import('../components/header-mobile').then((module) => {
                    module.default.init(initializer);
                    this.state.finished++;
                });
                break;
            case 'global-search':
                import('../components/global-search').then((module) => {
                    module.default.init(initializer);
                    this.state.finished++;
                });
                break;
            case 'meta-navigation':
                import('../components/meta-navigation').then((module) => {
                    module.default.init(initializer);
                    this.state.finished++;
                });
                break;
            case 'main-navigation':
                import('../components/main-navigation').then((module) => {
                    module.default.init(initializer);
                    this.state.finished++;
                });
                break;
            case 'footer-navigation':
                import('../components/footer-navigation').then((module) => {
                    module.default.init(initializer);
                    this.state.finished++;
                });
                break;
            case 'swiper':
                import('../components/swiper-factory').then((module) => {
                    module.default.initSwiperOnElement(initializer);
                    this.state.finished++;
                });
                break;
            case 'tabs':
                import('../components/tabs').then((module) => {
                    module.default.init(initializer);
                    this.state.finished++;
                });
                break;
            case 'anchor-smooth-scroll':
                import('../components/anchor-smooth-scroll').then((module) => {
                    module.default.init();
                    this.state.finished++;
                });
                break;
            case 'suggest-input':
                import('../components/suggest-input').then((module) => {
                    module.default.init(initializer);
                    this.state.finished++;
                });
                break;
            case 'skip-links':
                import('../components/skip-links').then((module) => {
                    module.default.init(initializer);
                    this.state.finished++;
                });
                break;
            case 'skip-over-links':
                import('../components/skip-over-links').then((module) => {
                    module.default.init(initializer);
                    this.state.finished++;
                });
                break;
            case 'woerterbuch':
                import('../components/woerterbuch').then((module) => {
                    module.default.init(initializer);
                    this.state.finished++;
                });
                break;
            case 'action-layer':
                import('../components/action-layer').then((module) => {
                    module.default.init(initializer);
                    this.state.finished++;
                });
                break;
            case 'beratungsstellen-suggest':
                import('../components/beratungsstellen-suggest').then((module) => {
                    module.default.init(initializer);
                    this.state.finished++;
                });
                break;
            case 'regionalpartner-profil-dropdown':
                import('../components/regionalpartner-profil-dropdown').then((module) => {
                    module.default.init(initializer);
                    this.state.finished++;
                });
                break;
            case 'map':
                import('../components/map').then((module) => {
                    module.default.init(initializer);
                    this.state.finished++;
                });
                break;
            case 'toast-handler':
                import('../components/toast-handler').then((module) => {
                    module.default.init(initializer);
                    this.state.finished++;
                });
                break;
            case 'tooltip':
                import('../components/tooltip').then((module) => {
                    module.default.init(initializer);
                    this.state.finished++;
                });
                break;
            case 'feedback':
                import('../components/feedback').then((module) => {
                    module.default.init(initializer);
                    this.state.finished++;
                });
                break;
            case 'adressverwaltung-liste-handler':
                import('../components/adressverwaltung-liste-handler').then((module) => {
                    module.default.init(initializer);
                    this.state.finished++;
                });
                break;
            case 'aktualisierungsanfrage-handler':
                import('../components/aktualisierungsanfrage-handler').then((module) => {
                    module.default.init();
                    this.state.finished++;
                });
                break;
            case 'materialbestellung-error-handler':
                import('../components/materialbestellung-error-handler').then((module) => {
                    module.default.init(initializer);
                    this.state.finished++;
                });
                break;
            case 'friendly-challenge':
                import('../components/friendly-challenge').then((module) => {
                    this.state.finished++;
                });
                break;
            case 'search-form-submit-type-tracking':
                import('../components/search-form-submit-type-tracking').then((module) => {
                    module.default.init(initializer);
                    this.state.finished++;
                });
                break;
            case 'ckeditor':
                import('../components/ckeditor').then((module) => {
                    module.default.init(initializer);
                    this.state.finished++;
                });
                break;
            case 'duplicates-popup':
                import('../components/duplicates-popup').then((module) => {
                    module.default.init(initializer);
                    this.state.finished++;
                });
                break;
            case 'address-search-list-map-toggle':
                import('../components/address-search-list-map-toggle').then((module) => {
                    module.default.init(initializer);
                    this.state.finished++;
                });
                break;
            case 'address-search-map':
                import('../components/address-search-map').then((module) => {
                    module.default.init(initializer);
                    this.state.finished++;
                });
                break;
            case 'familienratgeber-chat':
                import('../components/familienratgeber-chat').then((module) => {
                    module.default.init(initializer);
                    this.state.finished++;
                });
                break;
            case 'familienratgeber-chat-opener':
                import('../components/familienratgeber-chat-opener').then((module) => {
                    module.default.init(initializer);
                    this.state.finished++;
                });
                break;
            default:
                console.warn(`InitializerService: No initializer found for "${initializerName}" via DOM element:`, initializer);
                this.state.finished++;
                break;
        }
    }
}

export default new InitializerService();
